<template>
  <div class="main-wrapper">
    <div class="qr-page-wrapper" v-if="qrView">
      <VueText sizeLevel="6" class="info" color="white-100">
        Raf üzerinde bulunan QR kodu okutunuz
      </VueText>
      <div class="qr-box">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <div class="dce-video-container"></div>
        <div class="dce-scanarea">
          <div class="dce-scanlight"></div>
        </div>
      </div>
      <div class="progress-wrapper">
        <VueText sizeLevel="9" class="progress-title" color="white-100">
          Tarama süreci
        </VueText>
        <div class="progress">
          <div
            class="progress-bar"
            :style="{
              width: percent,
            }"
          ></div>
        </div>
      </div>
    </div>
    <input
      type="file"
      name="image"
      @change="getPhoto"
      @click="closeModal"
      ref="fileInput"
      hidden="true"
      capture="environment"
    />
    <div class="racks" v-if="!qrView">
      <div class="top-wrapper">
        <div class="store-info">
          <VueText sizeLevel="4" color="grey-30" weightLevel="3" class="title">Mağaza Adı</VueText>
          <VueText sizeLevel="6" color="grey-40" weightLevel="3">{{ this.customerName }} </VueText>
          <VueSpacer level="15" />
          <VueText
            sizeLevel="4"
            color="grey-30"
            weightLevel="3"
            :class="{ greenText: greenText, ['total']: true }"
            >Toplam Raf Sayısı: {{ this.rackCount }}</VueText
          >
          <VueText sizeLevel="4" color="grey-30" :class="{ greenText }" weightLevel="3"
            >Fotoğraf Çekilen Raf Sayısı: {{ this.racks.length }}</VueText
          >
        </div>
        <div class="progress">
          <div
            class="progress-bar"
            :style="{
              width: percent,
              backgroundImage: bgImage,
            }"
          ></div>
        </div>
        <div class="rack-list">
          <RackImage
            v-for="image in this.rackImages"
            :data="image"
            :key="image.id"
            @removeImage="removeImage"
            @changeImage="changeImage"
          />
          <div class="get-photo-row" v-if="!maxCount" @click="takePhoto()">
            <div v-if="this.rackImages.length < 2">
              <div class="cam-icon-wrapper">
                <VueIcon iconName="IconCameraBig" :width="23" :height="18"></VueIcon>
              </div>
              <VueText weightLevel="3">Fotoğraf Çek</VueText>
            </div>
          </div>
        </div>
      </div>

      <BrandButton
        :disabled="disabledButton"
        :size="sizes.xxLarge"
        :contentAlignment="constants.flexAlignment.center"
        type="submit"
        @click="checkSkus"
      >
        RAFI TAMAMLA</BrandButton
      >
    </div>
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import { ICON_VARIABLES, COMPONENT_SIZES } from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import RackImage from '@/views/pages/secure/imageRecognition/RackImage.vue';
import '../../../../../src/dbr';
import { mapGetters } from 'vuex';
import ImageRecognition from '@/services/Api/imageRecognition.js';
import { v4 as uuidv4 } from 'uuid';
import loadImage from 'blueimp-load-image';
import VueSpacer from '@/components/shared/VueSpacer/VueSpacer.vue';
import { BarcodeScanner } from 'dynamsoft-javascript-barcode';
export default {
  name: 'Main',
  components: { VueText, VueIcon, BrandButton, RackImage, VueSpacer },
  data: function() {
    return {
      isContextDestroyed: false,
      scanner: null,
      rackImage: {},
      uploadData: [],
      isSecondUrl: false,
      currentRackNumber: 0,
      isUpdate: false,
      updateId: null,
      percentCompleted: 0,
      fail: false,
      success: false,
      qrView: true,
      rackCount: 0,
      customerName: '',
      customerCode: 0,
      racks: [],
      rackImages: [],
      uploadedImages: [],
      approvedRacks: [],
      skuId: null,
      racksNumber: [],
      allRacksImages: [],
    };
  },
  computed: {
    ...mapGetters('app', ['getRacks', 'getLocation', 'getCloseClick']),
    isCloseClick() {
      return this.getCloseClick;
    },
    sizes() {
      return COMPONENT_SIZES;
    },
    icons() {
      return ICON_VARIABLES;
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    percent() {
      return `${this.percentCompleted}%`;
    },
    bgImage() {
      return (
        this.racks.length === this.rackCount && `linear-gradient(to right, #83cb1b, #1bc04a 98%)`
      );
    },
    greenText() {
      return this.racks.length && this.racks.length === this.rackCount;
    },
    disabledButton() {
      return !this.rackImages.length;
    },
    maxCountMessage() {
      return this.racks.length > 0 && this.racks.length === this.rackCount * 2
        ? 'Maksimum fotoğraf sayısına ulaştınız, gönderim yapabilirsiniz.'
        : '';
    },
    maxCount() {
      return this.racks.length > 0 && this.racks.length === this.rackCount * 2;
    },
  },
  created() {
    this.setBottomBarStatus(false);
  },
  mounted() {
    this.readQr();
  },
  async beforeDestroy() {
    this.isContextDestroyed = true;
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('app/setQrViewStatus', false);
    next();
  },
  watch: {
    // percentCompleted: {
    //   handler: function() {},
    //   deep: true,
    //   immediate: true,
    // },
    // racks: {
    //   handler: function() {},
    //   deep: true,
    //   immediate: true,
    // },
    qrView: {
      handler: function(newVal) {
        this.$store.dispatch('app/setQrViewStatus', newVal);
      },
      deep: true,
      immediate: true,
    },
    isCloseClick: {
      handler: function(isTrue) {
        isTrue && this.readQr();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setBottomBarStatus(status) {
      this.$store.dispatch('app/setBottomBarStatus', status);
    },
    async readQr() {
      try {
        this.percentCompleted = 0;
        this.scanner = await BarcodeScanner.createInstance();
        if (this.isContextDestroyed) {
          this.scanner.destroyContext();
          return;
        }
        await this.scanner.setUIElement(this.$el);
        this.scanner.onFrameRead = results => {
          if (results.length) {
            let data = {
              qrData: results[0].barcodeText,
            };
            this.getQrData(data);
            this.scanner.destroyContext();
          }
        };
        await this.scanner.open();
      } catch (ex) {
        console.error(ex);
      }
    },
    getQrData(data) {
      this.percentCompleted = 0;
      let interval = setInterval(() => {
        this.percentCompleted += 10;
      }, 50);
      // let data = { qrData: 'E5bmSdKf5dPnSWP9YScBoI6Ajjm8UJlqNwZo+PfbnSo=' };
      ImageRecognition.qrRead(data)
        .then(res => {
          if (res.status === 200 && res.data.Data && res.data.Data.images) {
            const { rackCount, customerName, customerCode, images } = res.data.Data;
            this.rackCount = rackCount;
            this.customerName = customerName;
            this.uploadData = images;
            this.currentRackNumber = images[0].rackNumber;
            clearInterval(interval);
            this.percentCompleted = 100;
            this.operations(customerCode);
          }
        })
        .catch(err => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.Error &&
            err.response.data.Error.Message
          ) {
            const modalConfig = {
              isModalOpened: true,
              modalText: `<div style="margin-bottom: 58px;">${err.response.data.Error.Message}</div>`,
              alertType: 'warn',
              firstButtonText: 'Tamam',
              modalType: 'alert',
              onCloseOpenQr: true,
              firstButtonFn: () => {
                this.readQr();
              },
            };
            this.$store.dispatch('app/setModalConfig', modalConfig);
          } else {
            const modalConfig = {
              isModalOpened: true,
              modalText: `<div style="margin-bottom: 58px;">Hata Oluştu!</div>`,
              alertType: 'warn',
              firstButtonText: 'Tamam',
              modalType: 'alert',
              onCloseOpenQr: true,
              firstButtonFn: () => {
                this.readQr();
              },
            };
            this.$store.dispatch('app/setModalConfig', modalConfig);
            ImageRecognition.errorLog({
              postCode: this.customerCode,
              detail: err.message,
            });
          }
        });
    },
    operations(customerCode) {
      if (this.rackImages.length === 0) {
        // ilk qr okuma
        const modalConfig = {
          isModalOpened: true,
          modalText: `<div style="font-size: 16px; color: #79838e; margin-top: 20px">Mağaza Adı</div>
                        <div style="font-size: 20px; margin-top: 12px; margin-bottom:58px;">${this.customerName}</div>`,
          isDoubleButton: true,
          firstButtonText: 'TAMAM',
          secondButtonText: 'İPTAL',
          modalConfirmationText: '',
          alertType: 'error',
          onCloseOpenQr: true,
          firstButtonFn: () => {
            this.takePhoto();
          },
          secondaryButtonFn: () => {
            this.readQr();
            this.qrView = true;
          },
        };
        // this.scanner.destroyContext();
        this.$store.dispatch('app/setModalConfig', modalConfig);
      } else {
        // ikinci ve sonraki qr okuma
        if (customerCode === this.customerCode) {
          if (this.rackImages.find(i => i.rackNumber === this.currentRackNumber)) {
            if (this.isUpdate) {
              const r = this.rackImages.find(i => i.id === this.updateId);
              if (r && r.rackNumber !== this.currentRackNumber) {
                const modalConfig = {
                  isModalOpened: true,
                  modalText: `
                        <div style="font-size: 20px; margin-top: 30px; margin-bottom:40px;">Değiştirmek istediğiniz raflar uyuşmuyor.</div>`,
                  firstButtonText: 'TAMAM',
                  modalConfirmationText: '',
                  alertType: 'error',
                  onCloseOpenQr: true,
                  firstButtonFn: () => {
                    this.readQr();
                  },
                };
                // this.scanner.destroyContext();
                this.$store.dispatch('app/setModalConfig', modalConfig);
              } else {
                this.isUpdate = true;
                const modalConfig = {
                  isModalOpened: true,
                  modalText: `<div style="font-size: 16px; color: #79838e; margin-top: 20px">Mağaza Adı</div>
                        <div style="font-size: 20px; margin-top: 12px; margin-bottom:58px;">${this.customerName}</div>`,
                  isDoubleButton: true,
                  firstButtonText: 'TAMAM',
                  secondButtonText: 'İPTAL',
                  modalConfirmationText: '',
                  alertType: 'error',
                  onCloseOpenQr: true,
                  firstButtonFn: () => {
                    this.takePhoto();
                  },
                  secondaryButtonFn: () => {
                    this.readQr();
                  },
                };
                // this.scanner.destroyContext();
                this.$store.dispatch('app/setModalConfig', modalConfig);
              }
            } else if (
              this.rackImages.filter(r => r.rackNumber === this.currentRackNumber).length === 2
            ) {
              const modalConfig = {
                isModalOpened: true,
                modalText: `
                        <div style="font-size: 20px; margin-top: 30px; margin-bottom:40px;">1 raf için en fazla 2 fotoğraf yüklenebilir.</div>`,
                firstButtonText: 'TAMAM',
                modalConfirmationText: '',
                alertType: 'error',
                onCloseOpenQr: true,
                firstButtonFn: () => {
                  this.readQr();
                },
              };
              // this.scanner.destroyContext();
              this.$store.dispatch('app/setModalConfig', modalConfig);
            } else {
              const modalConfig = {
                isModalOpened: true,
                modalText:
                  'Okunan QR kodu için daha önce bir fotoğraf yüklediniz, bu QR kodu için yapmak istediğiniz işlem nedir?',
                isDoubleButton: true,
                firstButtonText: 'FOTOĞRAF EKLE',
                secondButtonText: 'DEĞİŞTİR',
                isBtnReverse: true,
                modalConfirmationText: '',
                onCloseOpenQr: true,
                firstButtonFn: () => {
                  this.takePhoto();
                },
                secondaryButtonFn: () => {
                  // this.isUpdate = true;
                  this.takePhoto();
                },
              };
              // this.scanner.destroyContext();
              this.$store.dispatch('app/setModalConfig', modalConfig);
            }
          } else {
            if (
              this.rackImages.find(i => i.rackNumber !== this.currentRackNumber) &&
              this.isUpdate
            ) {
              const modalConfig = {
                isModalOpened: true,
                modalText: `
                        <div style="font-size: 20px; margin-top: 30px; margin-bottom:40px;">Değiştirmek istediğiniz raflar uyuşmuyor.</div>`,
                firstButtonText: 'TAMAM',
                modalConfirmationText: '',
                alertType: 'error',
                onCloseOpenQr: true,
                firstButtonFn: () => {
                  this.readQr();
                },
              };
              // this.scanner.destroyContext();
              this.$store.dispatch('app/setModalConfig', modalConfig);
            } else {
              const modalConfig = {
                isModalOpened: true,
                modalText: `<div style="font-size: 16px; color: #79838e; margin-top: 20px">Mağaza Adı</div>
                        <div style="font-size: 20px; margin-top: 12px; margin-bottom:58px;">${this.customerName}</div>`,
                isDoubleButton: true,
                firstButtonText: 'TAMAM',
                secondButtonText: 'İPTAL',
                modalConfirmationText: '',
                alertType: 'error',
                onCloseOpenQr: true,
                firstButtonFn: () => {
                  this.takePhoto();
                },
                secondaryButtonFn: () => {
                  this.readQr();
                },
              };
              // this.scanner.destroyContext();
              this.$store.dispatch('app/setModalConfig', modalConfig);
            }
          }
        } else {
          // customer code uyuşmuyor
          const modalConfig = {
            isModalOpened: true,
            modalText: `<div style="margin-bottom: 58px;"><b>Üzgünüz</b>, QR kod bilgileri ile fotoğraf mağaza bilgileri uyuşmamaktadır.</div>`,
            isDoubleButton: true,
            alertType: 'warn',
            firstButtonText: 'YENİDEN DENE',
            secondButtonText: 'İPTAL',
            modalType: 'alert',
            onCloseOpenQr: true,
            firstButtonFn: () => {
              this.readQr();
              this.$store.dispatch('app/setModalConfig', {});
            },
            secondaryButtonFn: () => {
              this.qrView = false;
            },
          };
          this.$store.dispatch('app/setModalConfig', modalConfig);
          // this.scanner.destroyContext();
        }
      }
      this.customerCode = customerCode;
    },
    takePhoto() {
      this.$refs.fileInput.click();
      this.isSecondUrl = this.rackImages.length === 1;
    },
    closeModal() {
      this.$store.dispatch('app/setModalConfig', {});
    },
    openQrReader() {
      window.scrollTo(0, 0);
      !this.$refs.fileInput?.value && this.readQr();
    },
    getPhoto({ target }) {
      this.readFile(target.files)
        .then(data => {
          target.value = '';
          // set state
          this.rackImage = { id: this.rackImage.id };
          this.rackImage.img = data;
          let date = new Date();
          let now = `${date.getFullYear()}/${date.getMonth() +
            1}/${date.getDate()} ${date.getHours()}:${(date.getMinutes() < 10 ? '0' : '') +
            date.getMinutes()}`;
          this.rackImage.date = now;
          this.rackImage.rackNumber = this.currentRackNumber;
          if (this.isUpdate) {
            let index;
            if (this.isUpdate) {
              index = this.rackImages.findIndex(i => i.id === this.updateId);
            } else {
              index = this.rackImages.findIndex(i => i.rackNumber === this.rackImage.rackNumber);
            }
            this.rackImages[index] = this.rackImage;
            this.racks = [...new Set(this.racks.map(item => item))];
            this.isUpdate = false;
            this.updateId = null;
          } else {
            this.rackImage.id = uuidv4();
            this.rackImages.push(this.rackImage);
            this.racks = [...this.racks, this.currentRackNumber];
            this.racks = [...new Set(this.racks.map(item => item))];
          }
          this.rackImages = this.rackImages.map((f, index) => {
            return { ...f, index: index + 1 };
          });
        })
        .catch(err => {
          target.value = '';
          ImageRecognition.errorLog({
            postCode: this.customerCode,
            detail: err.message,
          });
        });
      // upload file
      if (this.uploadData.length) {
        let uploadUrl = !this.isSecondUrl
          ? this.uploadData[0].uploadUrl
          : this.uploadData[1].uploadUrl;
        loadImage(target.files[0], { canvas: false, meta: true })
          .then(data => {
            var canvas = document.createElement('canvas');
            canvas.height = data.image.height;
            canvas.width = data.image.width;
            var ctx = canvas.getContext('2d');
            ctx.drawImage(data.image, 0, 0);
            canvas.toBlob(
              blob => {
                var img = new File([blob], 'test.jpg', { type: 'image/jpeg' });
                this.qrView = false;
                ImageRecognition.uploadImage(uploadUrl, img, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'No Auth',
                  },
                })
                  .then(res => {
                    if (res.status === 200) {
                      const idx = this.rackImages.findIndex(x => x.id === this.rackImage.id);
                      this.rackImages[idx].isSuccess = true;
                    }
                  })
                  .catch(err => {
                    ImageRecognition.errorLog({
                      postCode: this.customerCode,
                      detail: err.message,
                    });
                  });
              },
              'image/jpeg',
              0.95,
            );
          })
          .catch(err => {
            ImageRecognition.errorLog({
              postCode: this.customerCode,
              detail: err.message,
            });
          });
      }
    },

    readFile(files) {
      return new Promise((resolve, reject) => {
        if (!files || files.length === 0) {
          resolve();
          return;
        }
        const file = files[0];
        if (/^image\/\w+$/.test(file.type)) {
          if (URL) {
            resolve({
              loaded: true,
              name: file.name,
              type: file.type,
              url: URL.createObjectURL(file),
            });
          } else {
            reject(new Error('Browser desteklenmiyor.'));
            ImageRecognition.errorLog({
              postCode: this.customerCode,
              detail: 'Browser desteklenmiyor.',
            });
          }
        } else {
          reject(new Error('Lütfen image tipinde bir görsel seçiniz.'));
          ImageRecognition.errorLog({
            postCode: this.customerCode,
            detail: 'Lütfen image tipinde bir görsel seçiniz.',
          });
        }
      });
    },
    removeImage(id) {
      if (id) {
        const confirmModalConfig = {
          isModalOpened: true,
          modalText: '<div style="margin-top: 10px;">Fotoğraf Silinecek.</div>',
          isDoubleButton: true,
          firstButtonText: 'TAMAM',
          firstButtonFn: () => {
            this.rackImages = this.rackImages.filter(i => i.id !== id);
            if (this.rackImages.length === 0) {
              this.racks = this.racks.filter(item => item !== this.currentRackNumber);
            }
            //  this.racks = this.rackImages.length ? this.racks : [];
            this.racks = [...new Set(this.racks.map(item => item))];
            this.$store.dispatch('app/setModalConfig', {});
          },
        };
        this.$store.dispatch('app/setModalConfig', confirmModalConfig);
      }
    },
    changeImage(id) {
      if (id) {
        this.isUpdate = true;
        this.updateId = id;
        this.takePhoto();
      }
    },
    checkSkus() {
      const filteredData = this.uploadData.filter(x =>
        this.rackImages.some(s => {
          return x.index === s.index;
        }),
      );
      filteredData.forEach(f => this.allRacksImages.push(f));

      const requestData = {
        images: this.isSecondUrl ? filteredData : [filteredData[0]],
        rackId: this.currentRackNumber,
        missingSKUId: this.skuId,
        //başta 0, sonra responsedan gelen
        location: this.getLocation,
        customerCode: this.customerCode,
        rackCount: this.rackCount,
      };
      this.racksNumber = this.racks.sort();
      ImageRecognition.checkMissingSkues(requestData).then(res => {
        if (res.status === 200) {
          let skuList = res.data.Data?.missingSkus.map(item => `<div>${item.skuName}</div>`);
          this.skuId = res.data.Data?.guid;
          //fe hediyesi
          let rackText = this.racks.length < 2 ? 'Rafta' : 'Raflarda';
          const modalConfig = {
            isModalOpened: true,
            modalText: `<div style="margin-top: 10px;"><div style="font-weight: 600; font-size: 24px;">${
              this.racksNumber
            } nolu ${rackText} Olmayan Ürünler</div>
            <div style="margin-top: 10px; text-align: left; overflow:auto; max-height:260px">${skuList.join(
              '',
            )}</div></div>
            <div style="color:red;font-style: italic;font-size: 18px";>Mağazada birden fazla raf bulunuyorsa ve olmayan ürünler diger raflarda var ise onaylayarak devam edebilirsiniz.</div>`,
            modalConfirmationText: '',
            alertType: 'error',
            isDoubleButton: true,
            firstButtonText: 'ONAYLA',
            secondButtonText: 'TEKRAR DENE',
            isBtnReverse: true,
            firstButtonFn: () => {
              this.$store.dispatch('app/setModalConfig', {});
              let images = this.isSecondUrl ? this.uploadData : [this.uploadData[0]];
              this.uploadedImages.push(...images);
              this.approvedRacks.push(this.currentRackNumber);
              this.approvedRacks = [...new Set(this.approvedRacks.map(item => item))];
              if (this.rackCount === this.approvedRacks.length) {
                this.setDefaultState();
                this.process();
              } else {
                this.setDefaultState();
                this.openQrReader();
              }
            },
            secondaryButtonFn: () => {
              this.$store.dispatch('app/setModalConfig', {});
              this.setDefaultState();
              this.openQrReader();
              this.racks = this.rackImages.length
                ? [...this.racks, this.currentRackNumber]
                : this.approvedRacks;
              this.racks = [...new Set(this.racks.map(item => item))];
            },
          };
          this.$store.dispatch('app/setModalConfig', modalConfig);
        }
      });
    },
    setDefaultState() {
      this.isSecondUrl = false;
      this.rackImages = [];
      this.rackImage = {};
      this.uploadData = [];
      this.isSecondUrl = false;
      this.isUpdate = false;
      this.updateId = null;
      this.percentCompleted = 0;
      this.fail = false;
      this.success = false;
      this.qrView = true;
    },
    process() {
      const requestData = {
        images: this.allRacksImages,
        location: this.getLocation,
        customerCode: this.customerCode,
        rackCount: this.rackCount,
      };
      const modalConfig = {
        isModalOpened: true,
        isUploading: true,
      };
      this.$store.dispatch('app/setStatusModalConfig', modalConfig);
      ImageRecognition.process(requestData)
        .then(res => {
          if (res.status === 200) {
            const modalConfig = {
              isModalOpened: true,
              isUploading: false,
              isSuccess: true,
            };
            this.$store.dispatch('app/setStatusModalConfig', { isModalOpened: false });
            setTimeout(() => {
              this.$store.dispatch('app/setStatusModalConfig', modalConfig);
            });
          }
        })
        .catch(err => {
          console.log(err.response.data.Error.Message);
          const modalConfig = {
            isModalOpened: true,
            isUploading: false,
            isFail: true,
          };
          this.$store.dispatch('app/setStatusModalConfig', { isModalOpened: false });
          setTimeout(() => {
            this.$store.dispatch('app/setStatusModalConfig', modalConfig);
          });
          if (!err.response.data.Error.Message) {
            ImageRecognition.errorLog({
              postCode: this.customerCode,
              detail: err,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  height: 100%;
}
.qr-page-wrapper {
  height: 100%;
  background-color: #959fae;
}
.info {
  max-width: 200px;
  margin: 0 auto;
  text-align: center;
  padding: 10px;
  font-weight: bold;
}
.qr-box {
  width: 80%;
  height: 300px;
  background-color: transparent;
  margin: 0 auto;
  margin-top: 50px;
  position: relative;
  border: 5px solid #c1cbdb;
  box-shadow: -11px 6px 30px 0px rgba(50, 50, 50, 0.57);

  span {
    background-color: transparent;
    width: 65px;
    height: 65px;
    position: absolute;
    &:nth-child(1) {
      top: -5px;
      left: -5px;
      border-top: 3px solid #fff;
      border-left: 3px solid #fff;
    }
    &:nth-child(2) {
      top: -5px;
      right: -5px;
      border-top: 3px solid #fff;
      border-right: 3px solid #fff;
    }
    &:nth-child(3) {
      bottom: -5px;
      right: -5px;
      border-bottom: 3px solid #fff;
      border-right: 3px solid #fff;
    }
    &:nth-child(4) {
      bottom: -5px;
      left: -5px;
      border-bottom: 3px solid #fff;
      border-left: 3px solid #fff;
    }
  }
}
@keyframes scanner-scanlight {
  from {
    top: 0;
  }
  to {
    top: 50%;
  }
}
.progress-wrapper {
  margin-right: auto;
  margin-left: auto;
  width: 170px;
  text-align: center;
  margin-top: 55px;
}
.progress-title {
  font-weight: 500;
  margin-bottom: 10px;
}
.progress {
  display: flex;
  height: 5px;
  overflow: hidden;
  background-color: #e9ecef;

  border-radius: 2.5px;
  &-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-image: linear-gradient(315deg, #d20051, #ffa300);
    transition: width 0.8s ease;
    border-radius: 2.5px;
  }
}
.top-wrapper {
  display: flex;
  flex-direction: column;
}
.store-info {
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.progress {
  display: flex;
  height: 2px;
  overflow: hidden;
  background-color: #e9ecef;
  &-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-image: linear-gradient(315deg, #d20051, #ffa300);
    transition: width 0.6s ease;
  }
}
.title,
.total {
  margin-bottom: 6px;
}
.rack-list {
  padding-left: 20px;
  padding-right: 20px;
}

input[type='file'] {
  display: none;
}
.get-photo-row {
  div {
    display: flex;
    align-items: center;
    margin-top: 17.5px;
    margin-bottom: 20px;
    color: #23303d;
    text-decoration: none;
  }
}
.get-photo {
  cursor: pointer;
}
.cam-icon-wrapper {
  background-image: linear-gradient(315deg, #d20051, #ffa300);
  width: 50px;
  height: 50px;
  border-radius: 50px;
  padding: 17px 15px;
  display: flex;
  align-items: center;
  margin-right: 14px;
}
.bottom-info {
  margin-top: auto;
  margin-bottom: 18px;
  color: #e5472d;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-top: 20px;
  .icon-info {
    margin-right: 5px;
  }
}
.greenText {
  background: -webkit-linear-gradient(85deg, #83cb1b, #1bc04a 98%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.loader {
  color: #ffffff;
  font-size: 5px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: absolute;
  bottom: 30px;
  right: 100px;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 9;
}
.dce-video-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.dce-scanarea {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.dce-scanlight {
  display: none;
  width: 100%;
  height: 3%;
  position: absolute;
  animation: 3s infinite dce-scanlight;
  border-radius: 50%;
  box-shadow: 0px 0px 2vw 1px #00e5ff;
  background: #fff;
}

@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em,
      -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em,
      -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0,
      -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em,
      -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em,
      -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0,
      -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
</style>
